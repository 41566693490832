.content-2{
    height: 150vh;
    width: 100%;
    background-color: #f5f5f5;
    /* text-align: center; */
    
}

.afro {
    position: absolute;
    right: 0;
    width: 40%;
    
     
}
.text  {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


.tequila {
    display: flex;
    align-items: center;
    flex-direction: column;
}


@media only screen and (max-width: 600px) {
    
  
     .afro{
      width: 90%;
    } 

    .text {
    width: 90%;

     }

     .content-2{
        height: 120vh;
    }

    
  
    
  }

  