.content-3 {
    background-color: #ffdf6c;
    width: 100%;
    /* height: 120vh; */
    text-align: center;
    padding-top: 30px;       
    
    
        
}
.table {
    width: 100%;
    display: flex;
    justify-content: center;
}

table.GeneratedTable {
    
    width: 80%;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #ffdf6c;
    border-style: solid;
    color: #202020;
  }
  
  table.GeneratedTable td, table.GeneratedTable th {
    border-width: 5px;
    border-color: #f5f5f5;
    border-style: solid;
    padding: 3px;
  }
  
  table.GeneratedTable thead {
    background-color: #f5f5f5;
  }

  .fotja{

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 41px;
    
  }
  .eny {
    filter:contrast(0.8);
    
    padding-top: 30px;

    width: 50%;
    height: auto;
    
  
  }

@media only screen and (max-width: 600px) {

    .eny{
        width: 80%;
    }

    /* .content-3 {
        height: 100vh;
    } */
}

@media only screen and (max-width: 1190px) {
    .foto2 { width: 300px;}

    
}
