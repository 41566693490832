.nav {
    background-color: #ffdf6c;

   

    color: #202020;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.nav li a {
    text-decoration: none;
    color: #202020;
    font-weight:700;
}
.nav li {
    list-style: none;

}
a:hover{
    background-color: #F5f5f5;
    
}
.bar {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
}

.logo {
    width: 100px;
    height: auto;
    padding-top: 10px;
}

