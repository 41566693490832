.header {
  background-image: url('../../media/back.png');
  background-repeat: no-repeat;
  background-size: cover;
    margin: auto;
    background-color:#202020 ;
    color: white;
    height: 90vh;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.foto{
    width: auto;
    height: 700px;
    position: absolute;
    right: 0;


    
    
}
#njeqind{
  font-size: 40px;
  background-color: #f5f5f5;
  color: #202020;
 
}
.content {
    width: 50%;
    height: auto;

    background:#ffdf6c;

  align-items: center;
    padding: 15px;
}





/* CSS */
.button-19 {
  width: 30px;
  appearance: button;
  background-color: #202020;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #94f61c;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}




@media only screen and (max-width: 600px) {
  .foto2{
      width: 250px;
  }

  /* .afro{
    height: 50px;
    width: 100px;
  } */

  .content {
    width: 90%;
  }
}