.footer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    background-color: #202020;
    color: #f5f5f5;
    flex-wrap: wrap-reverse;
    padding-top: 2rem

}
.info{
    align-items: center;
    text-align: center;

}

.info h2
 {  font-weight: 500;

    font-family: 'Space Mono', monospace;
}

.logo2 {
    width: 200px;
}

.footer-right {
    text-align: center;
}

.menu a {
    text-decoration: none;
    color: #ffdf6c;
}